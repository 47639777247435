import { CSSReset, ThemeProvider } from '@chakra-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as UrqlProvider, createClient } from 'urql';
import Routes from './components/Routes';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { customTheme } from './theme';

const client = createClient({
  url: process.env.REACT_APP_GRAPHQL_URL,
});

ReactDOM.render(
  <ThemeProvider theme={customTheme}>
    <CSSReset />
    <UrqlProvider value={client}>
      <Routes />
    </UrqlProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
