import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../../assets/logo';

export default function Footer (props: any) {
  return (
    <section className="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-3">
            <h2
              onClick={() => props.history.push('/')}
              className="footer-header"
            >
              <Logo stroke='#fff' fill="#262626" /> 
            </h2>
            <small className="blue">Happiness is a clean home</small>
            <br />
            <p className="footer-p">
              <a href="tel:+1 (929) 497 4111">+ 1 (929) 497 4111</a> <br />
              <a href="mailto:support@heyhomero.com">support@heyhomero.com</a>
            </p>
          </div>
          <div className="col-sm-12 col-lg-3">
            <br />
            <ul className="footer-list">
              <p className="blue">
                <strong>Company</strong>
              </p>
              <li>
                <Link to="/mission">Our Mission</Link>
              </li>
              <li>
                <Link to="/house-cleaning-services-new-york">Locations</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <a href="https://blog.heyhomero.com?utm_source=heyhomero_footer" target="_blank" rel="noopener noreferrer">
                  Blog
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-lg-3">
            <br />
            <ul className="footer-list">
              <p className="blue">
                <strong>Our Services</strong>
              </p>
              <li>
                <a href="/services">Home Cleaning</a>
              </li>
              <li>
                <Link to="/moving">Moving Cleaning</Link>
              </li>
              <li>
                <a href="/happiness-guarantee">Happiness Guarantee</a>
              </li>
              <li>
                <a href="/cancellation-policy">Cancellation Policy</a>
              </li>
              <li>
                <a href="/contact">Help</a>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-lg-3">
            <br />
            <ul className="footer-list">
              <p className="blue">
                <strong>Social Media</strong>
              </p>
              <li className="social-list-item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/homero.cleaning/"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/homero.cleaning/"
                >
                  <i className="fab fa-facebook"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/homero_cleaning"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row copyrights">
          <div className="col-sm-12 col-lg-4">
            <small>&copy; 2024 Homero. All Rights Reserved</small>
          </div>
          <div className="col-sm-12 col-lg-4">
            <small className="made-at-ny">
              Made with{' '}
              <span style={{ color: '#ff0000', fontSize: '1.1rem' }}>
                &nbsp;♥&nbsp;
              </span>{' '}
              in New York.
            </small>
          </div>
          <div className="col-sm-12 col-lg-4">
            <small>
              <a href="/terms">Terms of Service</a> &nbsp; &nbsp; | &nbsp;
              &nbsp; <a href="/privacy">Privacy Policy</a>
            </small>
          </div>
        </div>
      </div>
    </section>
  );
};
