import React, { Suspense, lazy, memo, useEffect } from 'react';
import { Layout } from '../styles/Hero.styles';
import Footer from '../util/Footer';
import SwappingSquaresSpinner from '../util/Spinner';

const Cities = lazy(() => import('./Cities'));
const CleaningTypes = lazy(() => import('./CleaningTypes'));
const Easy123 = lazy(() => import('./Easy123'));
const FAQs = lazy(() => import('./FAQs'));
const GetStarted = lazy(() => import('./GetStarted'));
const Hero = lazy(() => import('./Hero'));
const Neighborhoods = lazy(() => import('./Neighborhoods'));
const Reviews = lazy(() => import('./Reviews'));

function LocationPage(props) {
  const city = props.city === "New York" ? "nyc" : props.city.toLowerCase();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
    <Layout style={{ background: '#fff' }}>
      <Suspense fallback={<SwappingSquaresSpinner />}>
        <Hero city={props.city} />
        <Easy123 />
        <CleaningTypes />
        {city === 'nyc' ? <Cities /> : <Reviews />}
        {city !== 'nyc' && <Neighborhoods city={props.city} />}
        <FAQs city={city} />
        <GetStarted />
        <Footer {...props} />
      </Suspense>
    </Layout>
  );
}

export default memo(LocationPage);
