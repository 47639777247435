import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense, lazy, useEffect, useMemo } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import LocationPage from './LocationPage';
import PopUp from './util/PopUp';

const App = lazy(() => import('./HomePage/App'));
const ServicesPage = lazy(() => import('./ServicesPage'));
const MissionsPage = lazy(() => import('./MissionsPage'));
const MovingPage = lazy(() => import('./MovingPage'));
const Contact = lazy(() => import('./Contact'));

const META = {
  '/': {
    title: 'Apartment Cleaning Services & House Cleaning - Homero',
    description:
      'Clean homes, happy New Yorkers: Discover our top-notch cleaning services in NYC!',
  },
  '/services': {
    title: "Try New York's Best House Cleaners - Homero",
    description:
      "Find a house cleaner near you now. Book a one time, weekly or biweekly home clean. New York's best home cleaning service.",
  },
  '/moving': {
    title: 'Moving In / Out Cleaning Service in New York - Homero',
    description:
      'Looking for Moving In/Out Cleaning service in New York? Calculate your home cleaning price and book online in just 2 minutes!',
  },
  '/mission': {
    title: 'About Homero | Read our mission here',
    description:
      'Homero is the modern cleaning company on a mission to revolutionize not only the way we view home cleaning, but also the way we view work and happiness.',
  },
  '/contact': {
    title: 'Contact Homero - Sustainable & Eco-Friendly Cleaning',
    description:
      "If you need help with something our FAQs don't cover, contact us – we're happy to help. Contact Homero.",
  },
  '/happiness-guarantee': {
    title: 'Happiness Guarantee - Homero',
    description:
      "Your happiness is our goal. If you're nothappy, we'll work to make it right. Q: What is the Happiness Guarantee?",
  },
  '/terms': {
    title: 'Terms of Service - Homero',
    description:
      'Welcome to Homero. Please read on to learn the rules and restrictions that govern your use of our services.',
  },
  '/privacy': {
    title: 'Privacy Policy - Homero',
    description:
      'We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information.',
  },
  '/cancellation-policy': {
    title: 'Cancellation Policy - Homero',
    description:
      'Change of plans? You may be charged a cancellation fee in certain conditions. Here is an overview of our cancellation policy.',
  },
  '/house-cleaning-services-new-york': {
    title: 'House Cleaning Services New York - Homero',
    description:
      'Homero offers affordable cleaning services for houses and apartments of all shapes & sizes in New York City. They are licensed and insured.',
  },
  '/house-cleaning-services-brooklyn': {
    title: 'House Cleaning Services Brooklyn - Homero',
    description:
      'Homero provides the best cleaning service for the Brooklyn, NYC area. See our service area map and a list of neighborhoods we service here.',
  },
  '/house-cleaning-services-manhattan': {
    title: 'House Cleaning Services Manhattan - Homero',
    description:
      'Homero provides the best cleaning service for the Manhattan, NYC area. See our service area map and a list of neighborhoods we service here.',
  },
  '/house-cleaning-services-queens': {
    title: 'House Cleaning Services Queens - Homero',
    description:
      'Homero provides the best cleaning service for the Queens, NYC area. See our service area map and a list of neighborhoods we service here.',
  },
};

const queryClient = new QueryClient();

export default function Routes() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<div>Loading....</div>}>
          <QueryClientProvider client={queryClient}>
            <SwitchRoutes />
          </QueryClientProvider>
        </Suspense>
      </BrowserRouter>
      <PopUp />
    </>
  );
}

function SwitchRoutes() {
  const { location } = useHistory();
  const { path } = useRouteMatch();

  const metaDesc = useMemo(
    () => document.querySelector('meta[name="description"]'),
    []
  );

  useEffect(() => {
    document.title = META[location.pathname]?.title || META['/'].title;
    metaDesc?.setAttribute(
      'content',
      META[location.pathname]?.description || META['/'].description
    );
  }, [location.pathname, path, metaDesc]);

  return (
    <Switch>
      <Route exact path="/" component={(props) => <App {...props} />} />
      <Route
        exact
        path="/services"
        component={(props) => <ServicesPage {...props} />}
      />
      <Route
        exact
        path="/moving"
        component={(props) => <MovingPage {...props} />}
      />
      <Route
        exact
        path="/mission"
        component={(props) => <MissionsPage {...props} />}
      />
      <Route
        exact
        path="/contact"
        component={(props) => <Contact {...props} file="Contact" />}
      />
      <Route
        exact
        path="/happiness-guarantee"
        component={(props) => <Contact {...props} file="Happiness" />}
      />
      <Route
        exact
        path="/terms"
        component={(props) => <Contact {...props} file="Terms" />}
      />
      <Route
        exact
        path="/privacy"
        component={(props) => <Contact {...props} file="Privacy" />}
      />
      <Route
        exact
        path="/cancellation-policy"
        component={(props) => <Contact {...props} file="Cancellation" />}
      />
      <Route
        exact
        path="/house-cleaning-services-new-york"
        component={(props) => <LocationPage city="New York" {...props} />}
      />
      <Route
        exact
        path="/house-cleaning-services-brooklyn"
        component={(props) => <LocationPage city="Brooklyn" {...props} />}
      />
      <Route
        exact
        path="/house-cleaning-services-manhattan"
        component={(props) => <LocationPage city="Manhattan" {...props} />}
      />
      <Route
        exact
        path="/house-cleaning-services-queens"
        component={(props) => <LocationPage city="Queens" {...props} />}
      />
    </Switch>
  );
}
