import styled from 'styled-components';

export const Headline = styled.h1`
  font-size: 3rem;
  width: ${(props) => props.width || '70%'};
  font-weight: 600;
  line-height: 4rem;
  font-family: Poppins, sans-serif;
  margin-top: ${(props) => props.mt || '25vh'};
  color: ${(props) => props.color || '#fff'};
  z-index: 1;
  strong {
    font-weight: 700 !important;
  }

  @media (max-width: 600px) {
    font-size: 2rem;
    margin-top: 20vh;
    line-height: 2.5rem;
    width: 100%;
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    font-size: 4rem;
    width: 100%;
    line-height: 4rem;
  }

  @media (max-width: 991px) and (orientation: landscape) {
    font-size: 1.5rem;
    width: 100%;
  }
  @media (max-width: 1024px) {
    margin-top: 25vh;
  }
`;

export const Button = styled.button`
  border: none;
  border-radius: 5px;
  color: ${(props) => props.color || '#fff'};
  padding: 1rem 2rem;
  background: ${(props) => props.background || '#253EC6'};
  text-align: center;
  font-family: Poppins, sans-serif;
  z-index: 1;

  :hover,
  :focus,
  :active {
    color: #fff;
    background-color: ${(props) => props.hoverBackground || '#323593'};
    border-color: #323593;
  }
`;

export const OutlineButton = styled.button`
  border: 1px solid #373744;
  border-radius: 5px;
  padding: 1rem 2rem;
  background-color: #fff;
  font-family: Poppins, sans-serif;
  color: ${(props) => props.color || '#373744'};

  :hover {
    color: #fff;
    background-color: #253ec6;
    border-color: #253ec6;
  }
`;

export const Layout = styled.div`
  background: ${(props) => props.background || 'transparent'};
`;

export const HeadlineTag = styled.h2`
  color: #253ec6;
  font-size: 1rem;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  margin: 0;
  font-weight: 600;
`;
