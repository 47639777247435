import { theme } from '@chakra-ui/core';

export const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    teal: {
      ...theme.colors.teal,
      900: '#253ec6',
      700: '#0093ED',
    },
    ghostBlue: "#E5F2F6",
  },
  fontSize: {
    ...theme.fontSize,
    'xs': '0.75rem',
    'sm': '0.875rem',
    'md': '1rem',
  },
  fonts: {
    heading: 'Poppins, sans-serif',
    body: 'Poppins, sans-serif',
  },
};
